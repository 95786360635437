<template>
	<div>
		  <b-button
			  id="buttonobservations"
			  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
			  variant="outline-dark"
			  class="btn-icon rounded-circle"
			  v-b-tooltip.hover.on.v-dark
			  title="Observaciones"
			  @click="getObservations()"
		  >
			  <feather-icon icon="MessageSquareIcon" />
		  </b-button>

		  <b-modal
			  ref="modal-view-observations"
			  :id="`modal-${provider}`"
			  centered
			  size="lg"
			  :title="titleModal"
			  modal-class="modal-primary"
			  no-close-on-backdrop
			  no-close-on-esc
		  >
				<b-card 
		  			class="mt-2"
					v-bind:style="skin=='light' ? 'background: rgba(248, 248, 248)':'background: rgb(22, 29, 49)'"
					v-for="(observation, index) in observations" :key="index">
					<b-card-text><strong>Pregunta #{{ observation.number }}:</strong> {{ observation.question }}</b-card-text>
					<b-card-text><strong>Observaciones:</strong> {{ observation.comments }}</b-card-text>
				</b-card>
  
			  <template #modal-footer="{ hide }">  
				  <b-button variant="outline-danger" @click="hide">
					  Cerrar
				  </b-button>
			  </template>
		  </b-modal>
		  
	</div>
  </template>
  
  <script>
  import Ripple from 'vue-ripple-directive'
  import moment from 'moment';
  import useAppConfig from '@core/app-config/useAppConfig';
  
  export default {
	  props: {
		  observations: Array,
		  provider: Number,        
	  },
	  directives: {
		  Ripple,
	  },
	  data() {
		  return {
			  titleModal: null
		  }
	  },
	  methods: {
		  getObservations(){     
			  const currentYear = moment().format('YYYY');
  
			  this.titleModal = `OBSERVACIONES ${currentYear}`;       
			  this.$refs['modal-view-observations'].show();
		  }
	  },
	  setup() {
            const { skin } = useAppConfig();         
            
            return { skin }
        }
  }
  </script>